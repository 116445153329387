.home {
	height: 100vh;
	color: white;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-color: black;
}

.home__title {
	margin-top: 200px;
	text-align: center;
}
