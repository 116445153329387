body {
	margin: 0;
	font-family: SFProDisplay-Regular, sans-serif;
	background-color: black;
	color: white;
	/* -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; */
}

@font-face {
	font-family: 'SFProDisplay-Regular';
	src: local('SFProDisplay-Regular'), url(./fonts/SFProDisplay-Regular.ttf);
}

@font-face {
	font-family: 'SFProDisplay-Bold';
	src: local('SFProDisplay-Bold'), url(./fonts/SFProDisplay-Bold.ttf);
}
