.maintitle__text {
    font-size: 3.5rem;
	font-family: SFProDisplay-Bold;
    text-transform: uppercase;
}

.maintitle__subtitle {
	font-family: SFProDisplay-Regular;
}

h1,
h2,
h3 {
	color: white;
}
