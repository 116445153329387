.nav {
	position: fixed;
	top: 0;
	width: 100%;
	height: 2.5rem;
	padding: 1rem 0;
	display: flex;
	z-index: 1;
	justify-content: center;
	align-items: center;
	/* Animation */

	-webkit-transition: all 0.5s ease-in;
	-moz-transition: all 0.5s ease-in;
	-o-transition: all 0.5s ease-in;
	transition: all 0.5s ease-in;
}



.nav__container {
	display: flex;
	width: 100%;
	max-width: 1800px;
	z-index: 1;
	justify-content: space-between;
	align-items: center;
}

.nav__brand {
	margin-left: 100px;
	text-decoration: none;
	font-family: SFProDisplay-Bold;
	font-weight: bold;
	font-size: 1.5rem;
	color: white;
	cursor: pointer;
	-webkit-transition: color 0.5s linear;
	-moz-transition: color 0.5s linear;
	-o-transition: color 0.5s linear;
	transition: color 0.5s linear;
}

.nav__black {
	background-color: #111;
}

.nav__black .nav__links li a {
	color: white !important;
}

.nav__black .nav__brand {
	color: white !important;
}

.nav__black .nav__links li a:hover {
	color: rgb(219, 218, 218) !important;
}

.nav__black .nav__brand:hover {
	color: rgb(219, 218, 218) !important;
}

.nav__brand:hover {
	-webkit-transition: color 0.25s linear;
	-moz-transition: color 0.25s linear;
	-o-transition: color 0.25s linear;
	transition: color 0.25s linear;
	color: #d1d1d1;
}

.nav__links {
	margin-right: 100px;
	list-style: none;
	display: flex;
	cursor: pointer;
}

.nav__links li a {
	display: block;
	padding-right: 20px;
	text-decoration: none;
	font-weight: bold;
	color: white;
	cursor: pointer;
	-webkit-transition: color 0.5s linear;
	-moz-transition: color 0.5s linear;
	-o-transition: color 0.5s linear;
	transition: color 0.5s linear;
}

.nav__links li a:hover {
	color: #d1d1d1;
	transition: color 0.25s linear;
	cursor: pointer;
}
